/*---------------------------

Overlay

---------------------------*/

.tww_overlay {
  &__container {
    @extend %global_reset;

    * {
      @extend %global_reset;
    }

    div {
      display: block;
    }

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 16777271;
    padding: 100px;
  }

  &__content {
    position: relative;
    height: calc(100vh - 200px);
    max-height: 650px;
    width: 100%;
    max-width: 1000px !important;
    margin: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    .tww_button {
      &__close {
        background: none;
        border: none;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        color: #000000;
        font: bold 20px "Verdana", sans-serif;
        cursor: pointer;

        &:focus, &:focus {
          outline: 0;
          color: #666666;
        }
      }
    }
  }

  &__iframe {
    border: none;
    border-radius: 5px;
    display: block;
    position: relative;
    height: 100%;
    max-height: 650px;
    width: 100%;
    overflow: hidden;
  }
}

@media all and (max-width: 960px) {
  .tww_overlay {
    &__container {
      padding: 50px;
    }

    &__content {
      max-height: 650px;
      height: calc(100vh - 100px);
    }

    &__iframe {
      max-height: 650px;
      height: calc(100vh - 100px);
    }

  }
}

@media all and (max-width: 768px) {
  .tww_overlay {
    &__container {
      padding: 0;
    }

    &__content {
      max-height: initial;
      height: 100vh;
    }

    &__iframe {
      border-radius: 0;
      height: 100vh;
      max-height: initial;
    }
  }
}
