/*---------------------------

Sidebar positions

---------------------------*/

.tww_sidebar {
    &_left {
        left: 5px;
    }
    &_left_jubilee {
        left: 17px;
    }
    &_center {
        left: calc(50% - 50px / 2);
    }
    &_right {
        right: 5px;
    }
    &_right_jubilee {
        right: 5px;
    }
    &_top {
        top: 5px;
    }
    &_middle {
        top: calc(50% - 147px / 2);
    }
    &_bottom {
        bottom: 5px;
    }
}

/*---------------------------

Sidebar elements

---------------------------*/

.tww_sidebar {
    &_container {
        @extend %global_reset;
        @extend %font_reset_sidebar;
        * {
            @extend %global_reset;
            @extend %font_reset_sidebar;
        }

        div {
            display: block;
        }

        position: fixed;
        z-index: 16777270;

        &.tww_sidebar_widget {
            width: 50px;

            .tww_sidebar_logo {
                width: 50px;
            }
        }

        &.tww_sidebar_small {
            width: 25px;

            .tww_sidebar_logo {
                width: 25px;
            }
        }

        &.tww_sidebar_normal {
            width: 30px;

            .tww_sidebar_logo {
                width: 30px;
            }
        }

        &.tww_sidebar_large {
            width: 40px;

            .tww_sidebar_logo {
                width: 40px;
            }
        }
    }

    &_logo {
        display: block;
        cursor: pointer;
    }
}

/*---------------------------

Sidebar buttons

---------------------------*/

.tww_sidebar_button {
    &_container {
        border: 2px solid black;
        border-top: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 8px 11px 11px 11px;
        background: white;
        text-align: center;
        a {
            color: #000000 !important;
        }
    }

    &_delivery,
    &_check,
    &_star {
        position: relative;
        background-size: 16px;
        background-position-x: 5px;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: #dddddd;
        box-sizing: border-box;
        display: block;
        height: 26px;
        width: 26px;
        padding-left: 26px;
        transition: 200ms;

        &_score,
        &_text {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
        }

        &_score {
            margin-left: -24px;
        }

        &_text {
            position: absolute;
            line-height: 26px;
            padding: 0;
            display: inline-block;
            white-space: nowrap;
            background: #dddddd;
            max-width: 0;
            overflow: hidden;
            transition: all .3s ease-in;
            text-align: left;
        }
        &:hover {
            .tww_sidebar_button_delivery_text,
            .tww_sidebar_button_check_text,
            .tww_sidebar_button_star_text {
                max-width: 250px;
            }
        }
    }

    &_check {
        background-image: url('./images/script/check.svg');
        margin-bottom: 6px;
    }

    &_delivery {
        background-image: url('./images/script/delivery.svg');
        margin-top: 6px;
        background-size: 26px;
        background-position-x: center;
    }
}

/*---------------------------

Left sidebar overwrite

---------------------------*/

.tww_sidebar_left,
.tww_sidebar_left_jubilee {
    .tww_sidebar_button_delivery,
    .tww_sidebar_button_check,
    .tww_sidebar_button_star {
        &_text {
            left: 100%;
        }
        &:hover {
            .tww_sidebar_button_delivery_text,
            .tww_sidebar_button_check_text,
            .tww_sidebar_button_star_text {
                padding-left: 10px;
                padding-right: 8px;
            }
        }
    }
}

/*---------------------------

Right sidebar overwrite

---------------------------*/

.tww_sidebar_right,
.tww_sidebar_right_jubilee {
    .tww_sidebar_button_delivery,
    .tww_sidebar_button_check,
    .tww_sidebar_button_star {
        &_text {
            right: 100%;
        }
        &:hover {
            .tww_sidebar_button_delivery_text,
            .tww_sidebar_button_check_text,
            .tww_sidebar_button_star_text {
                padding-left: 8px;
                padding-right: 10px;
            }
        }
    }
}

/*---------------------------

Jubilee sidebar overwrite

---------------------------*/

.tww_sidebar_left_jubilee,
.tww_sidebar_right_jubilee {
    .tww_sidebar_logo {
        width: 62px !important;
        margin-left: -12px;
    }

    &.tww_sidebar_small {
        width: 31px !important;

        .tww_sidebar_logo {
            width: 31px !important;
        }
    }

    &.tww_sidebar_normal {
        width: 37px !important;

        .tww_sidebar_logo {
            width: 37px !important;
        }
    }

    &.tww_sidebar_large {
        width: 50px !important;

        .tww_sidebar_logo {
            width: 50px !important;
        }
    }
}

.tww_sidebar_right_jubilee {
    &.tww_sidebar_small,
    &.tww_sidebar_normal,
    &.tww_sidebar_large {
        .tww_sidebar_logo {
            margin-left: 0 !important;
        }
    }
}