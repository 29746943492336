/*---------------------------

Popup elements

---------------------------*/

.tww_popup {
  &_wrapper {

    @extend %global_reset;
    @extend %font_reset_popup;

    * {
      @extend %global_reset;
      @extend %font_reset_popup;
    }

    div {
      display: block !important;
      text-align: center !important;

      &.tww_popup__logo {
        display: inline-block !important;
      }
    }

    text-align: center;
    text-transform: none;
  }

  &__logo {

    //nodrag

    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    height: 3rem;
    display: inline-block !important;

    > .tww_popup__image {
      @extend %global_reset;
      height: 100%;
    }

    &:hover {
      .tww_popup__container {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__container {
    position: fixed;
    background-color: $bg;
    border: 1px solid $border;
    border-radius: 5px;
    width: calc(100% - #{$offset});
    max-width: 400px;
    top: -100%;
    left: -100%;
    opacity: 0;
    pointer-events: none;
    z-index: 16777270;
    color: black;

    &:after {
      content: "";
      position: absolute;
      top: -$offset;
      left: -$offset;
      width: calc(100% + (#{$offset } * 2));
      height: calc(100% + (#{$offset } * 2));
      z-index: -8;
    }

    .tww_header {
      &__title {
        font-size: 20px;
        line-height: 25px;
      }

      &__top {
        border-bottom: 1px solid;
        display: table !important;
        width: 100%
      }

      &__bottom {
        padding: 1em;
        text-align: center;

        &__body {

        }
      }

      &__row {
        display: table-row !important;

        &__col-50,
        &__col-100 {
          vertical-align: middle;
          display: table-cell !important;
          padding: 1em;
        }

        &__col-50 {
          width: 50%;
        }

        &__col-100 {
          width: 100%;
        }
      }

      &__score {
        &__numerator {
          font-size: 24px;
          font-weight: bold;
        }

        &__denominator {
          font-size: 24px;
          font-weight: bold;
          color: #cccccc;
        }
      }

      &__content {
        margin: 1em 0;
      }

      &__company {
        display: initial !important;
        font-weight: bold;
      }

      &__logo {
        img,
        &__image {
          @extend %global_reset;

          float: none !important;
          margin: 0;
          display: inline !important;
          width: 95px !important;
          height: 95px !important;
        }
      }

      &__rating_star {
        width: 15px !important;
        height: 15px !important;
        margin: 0 !important;
        padding: 0 !important;
        display: inline-block !important;
        float: none !important;
      }
    }

    .tww__button {
      transition: background-color .2s ease;
      cursor: pointer;
      background: $button;
      color: #000000;
      font-weight: bold;
      line-height: 40px;
      padding: 0.8em 2em;
      display: inline !important;

      &:hover {
        background-color: darken($button, 5);
      }
    }
  }
}
