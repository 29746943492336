%border_reset {
  border-bottom: initial;
  border-bottom-color: initial;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial;
  border-bottom-style: initial;
  border-bottom-width: initial;

  border-top: initial;
  border-top-color: initial;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
  border-top-style: initial;
  border-top-width: initial;

  border-left: initial;
  border-left-color: initial;
  border-left-style: initial;
  border-left-width: initial;

  border-right: initial;
  border-right-color: initial;
  border-right-style: initial;
  border-right-width: initial;

  outline: initial;
  outline-color: initial;
  outline-offset: initial;
  outline-style: initial;
  outline-width: initial;
}

%background_reset {
  background-attachment: initial;
  background-blend-mode: initial;
  background-clip: initial;
  background-color: initial;
  background-image: initial;
  background-position: initial;
  background-repeat: initial;
  background-size: initial;
  background-position-x: initial;
  background-position-y: initial;
}

%font_reset_popup {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  font-weight: normal;
}

%font_reset_sidebar {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

%text_reset {
  color: initial;

  direction: initial;

  font-feature-settings: initial;
  font-kerning: initial;
  font-size-adjust: initial;
  font-stretch: initial;
  font-style: initial;
  font-synthesis: initial;
  font-variant: initial;
  font-variant-alternates: initial;
  font-variant-caps: initial;
  font-variant-east-asian: initial;
  font-variant-ligatures: initial;
  font-variant-numeric: initial;
  font-variant-position: initial;

  line-height: initial;
  letter-spacing: initial;

  text-align: initial;
  text-decoration: initial;
  text-indent: initial;
  text-shadow: initial;
  text-transform: initial;

  white-space: initial;
  word-break: initial;
  word-spacing: initial;
}

%flex_reset {
  flex: initial;
  flex-basis: initial;
  flex-direction: initial;
  flex-flow: initial;
  flex-grow: initial;
  flex-shrink: initial;
  flex-wrap: initial;
}

%position_reset {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: initial;
  position: initial;
  visibility: initial;

  float: initial;
  clear: initial;

  height: initial;
  min-height: initial;
  max-height: initial;

  width: initial;
  min-width: initial;
  max-width: initial;

  margin-bottom: initial;
  margin-left: initial;
  margin-right: initial;
  margin-top: initial;

  padding-bottom: initial;
  padding-left: initial;
  padding-top: initial;
  padding-right: initial;

  bottom: initial;
  left: initial;
  top: initial;
  right: initial;

  overflow: initial;
  overflow-scrolling: initial;
  overflow-style: initial;
  overflow-wrap: initial;
  overflow-x: initial;
  overflow-y: initial;

  vertical-align: initial;

  cursor: initial;
}

%table_reset {
  border-collapse: initial;
  border-spacing: initial;
  caption-side: initial;
  empty-cells: initial;
  table-layout: initial;
}

%global_reset {
  @extend %background_reset;
  @extend %border_reset;
  @extend %flex_reset;
  @extend %text_reset;
  @extend %position_reset;
  @extend %table_reset;
}